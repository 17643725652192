import { useLocation } from '@reach/router';
import { FormikHelpers } from 'formik';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ReactElement, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import Stepper from 'display-components/stepper/stepper';

// UI Kit & Components
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import MembershipRegistrationForm from 'components/membership-registration-form/membership-registration-form.component';
import WorkflowFooterLinks from 'components/workflow-footer-links/workflow-footer-links';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

// state
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import {
    setMembershipFormValues,
    setSelectedPlanId
} from 'state/membership-registration/membership-registration.reducer';
import { membershipRegistrationFormValuesSelector } from 'state/membership-registration/membership-registration.selectors';
import { MembershipEmailZipcodePayload } from 'state/membership-registration/membership-registration.services';

// Types and interfaces
import { GetStartedFormValues } from 'types/membership';

import { getPhoneNumber } from 'util/globalVariables';
import { ZipValidateResponse } from 'util/usps';

// hooks
import { useAddressVerification } from 'hooks/useAddressVerification';
import { useEmailZipcodeVerification } from 'hooks/useEmailZipcodeVerification';

import './index.style.scss';

const GetStarted = ({
    location,
    data
}: {
    location: any;
    data: GatsbyTypes.MembershipGetStartedDataQuery;
}): ReactElement => {
    const { t } = useTranslation();
    const pathLocation = useLocation();
    const dispatch = useDispatch();
    const { verifyZip } = useAddressVerification();
    const { verifyEmailZipcode } = useEmailZipcodeVerification();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const formValues = useSelector(membershipRegistrationFormValuesSelector);

    const initialValues = {
        email: undefined,
        zipcode: undefined
    };

    const urlParams = new URLSearchParams(pathLocation.search);
    const planId = urlParams.get('plan');

    // Footer links selector
    const workflowFooterLinks =
        data.allBlockContentRegistrationFlowFooterLinks?.edges?.[0]?.node?.field_registration_footer_link ?? [];

    useEffect(() => {
        // has a redirect request (navigating back from Gov. Insurance)
        // location -vs- window.location : using pushState clears the window object but not the component `location`.
        if (window.location?.search && window.location?.search === '?redirect=/membership/gov-insurance') {
            window.history.pushState({}, '', '/get-started/membership');
            return;
        }

        // tracking pageview
        _cio.page(window.location.href);
    }, []);

    useEffect(() => {
        dispatch(setSelectedPlanId(parseInt(planId ?? '')));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planId]);

    const initialErrors = {
        email: false,
        zipcode: false
    };
    const [errors, setErrors] = useState(initialErrors);

    const handleCloseModal = () => {
        dispatch(closeModal({}));
    };

    const handleErrorModal = (validationResponse: ZipValidateResponse) => {
        if (validationResponse.responseCode === 'invalid') {
            setErrors((errors) => ({
                ...errors,
                zipcode: true
            }));
        } else {
            dispatch(
                openModal({
                    bodyContent: (
                        <BirdiModalContentAlt
                            subTitle={t('registration.errors.modals.default.body')}
                            note={t('registration.errors.modals.default.note', {
                                phoneNumber: getPhoneNumber({ isEnd: true })
                            })}
                        />
                    ),
                    ctas: [
                        {
                            dataGALocation: 'ProfileSetupError',
                            label: t('registration.errors.modals.default.button'),
                            onClick: () => {
                                dispatch(closeModal({}));
                            },
                            variant: 'primary'
                        }
                    ],
                    headerContent: (
                        <BirdiModalHeaderDanger
                            headerText={t('registration.errors.modals.default.title')}
                            icon="alert"
                        />
                    ),
                    showClose: true,
                    type: 'danger',
                    size: 'lg'
                })
            );
        }
    };
    const handleEmailZipCodeErrorModal = () => {
        dispatch(
            openModal({
                showClose: false,
                onClose: handleCloseModal,
                size: 'lg',
                type: 'danger',
                contentClassName: 'modal-content-padding',
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('registration.errors.modals.emailAlreadyOnFile.title')}
                        icon="alert"
                    />
                ),
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('registration.errors.modals.emailAlreadyOnFile.body')}
                        note={t('registration.errors.modals.emailAlreadyOnFile.note')}
                    />
                ),
                ctas: [
                    {
                        label: t('registration.errors.modals.emailAlreadyOnFile.button'),
                        variant: 'primary',
                        onClick: handleCloseModal
                    }
                ]
            })
        );
    };

    const getEmailZipcode = async (values: GetStartedFormValues) => {
        setIsSubmitting(true);

        verifyZip({
            zip: values.zipcode ?? '',
            onSuccess: (validationResponse: ZipValidateResponse) => {
                dispatch(
                    setMembershipFormValues({
                        email: values.email ?? '',
                        zipcode: values.zipcode ?? '',
                        city: validationResponse?.address?.city,
                        state: validationResponse?.address?.state
                    })
                );

                verifyEmailZipcode({
                    email: values.email ?? '',
                    zipcode: values.zipcode ?? '',
                    onSuccess() {
                        setIsSubmitting(false);
                        navigate('/get-started/gov-insurance');

                        // Identify event :: push attribute(s) to customer.io
                        _cio.identify({
                            id: values.email,
                            // Strongly recommended when you first identify someone
                            created_at: Math.floor(Date.now() / 1000)
                        });
                    },
                    onFailure(validationResponse: MembershipEmailZipcodePayload) {
                        switch (validationResponse?.validate) {
                            case 'registered':
                                handleEmailZipCodeErrorModal();
                                break;
                            case 'invalid':
                                setErrors((errors) => ({
                                    ...errors,
                                    email: true
                                }));
                                break;
                            default:
                                handleErrorModal({
                                    ...validationResponse,
                                    responseCode: undefined
                                });
                                break;
                        }
                        setIsSubmitting(false);
                    }
                });
            },
            onFailure(validationResponse: ZipValidateResponse) {
                if (validationResponse.responseCode === 'invalid') {
                    setErrors((errors) => ({
                        ...errors,
                        zipcode: true
                    }));
                } else {
                    handleErrorModal(validationResponse);
                }
                setIsSubmitting(false);
            }
        });
    };

    const onSubmit = async (
        values: Partial<GetStartedFormValues>,
        helpers: FormikHelpers<Partial<GetStartedFormValues>>
    ) => {
        // Clear errors before re-submitting
        setErrors(initialErrors);

        await getEmailZipcode(values);
    };

    return (
        <WorkflowLayout
            backgroundImage={data.backgroundImage}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('membership.getStarted.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                className="membership-get-started-uninsured"
                currentFlow={'membership-registration'}
                eyebrowText={t('membership.getStarted.uninsured.eyebrowText')}
                title={t('membership.getStarted.uninsured.profileTitle')}
            >
                <div className="membership-get-started-uninsured-subtitle">
                    <p>{t('membership.getStarted.uninsured.subTitle')}</p>
                </div>
                <MembershipRegistrationForm
                    initialValues={!formValues?.email && !formValues?.zipcode ? initialValues : formValues}
                    errors={errors}
                    isBusy={isSubmitting}
                    onSubmit={onSubmit}
                />
                <div className="membership-get-started-uninsured-terms-and-privacy">
                    <p>
                        {t('membership.getStarted.uninsured.terms.phrase')}{' '}
                        <Alert.Link href="/website-terms-of-use">
                            {t('membership.getStarted.uninsured.terms.termsOfUse')}
                        </Alert.Link>{' '}
                        {t('membership.getStarted.uninsured.terms.and')}{' '}
                        <Alert.Link href="/website-privacy-policy">
                            {t('membership.getStarted.uninsured.terms.privacyPolicy')}
                        </Alert.Link>{' '}
                    </p>
                </div>
                <div className="md:mt-5">
                    <Stepper activeStep={0} steps={['1', '2', '3', '4']} />
                </div>
            </WorkflowLayoutFormWrapper>

            {/* Workflow links */}
            <WorkflowFooterLinks links={workflowFooterLinks as any} locationGAflow="membership" />
        </WorkflowLayout>
    );
};
export default GetStarted;

export const query = graphql`
    query MembershipGetStartedData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        backgroundImage: file(relativePath: { eq: "assets/images/white-feathers-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        allBlockContentRegistrationFlowFooterLinks(
            filter: { field_registration_flow_types: { eq: "membership_flow" } }
        ) {
            edges {
                node {
                    field_registration_footer_link {
                        title
                        uri
                    }
                }
            }
        }
    }
`;
